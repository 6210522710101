import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./report.css";

const PerformanceReportListView = (data) => {
  const reportData = data.reportData
  const extractCompanyName = (url) => {
    const { protocol, hostname } = new URL(url);
    let name = hostname.replace(/^www\./, '').replace(/\.com$/, '')
    return name;
  };
  
  return (
    <div className="row">
      <div className="col-lg-12">

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade active show" id="home" role="tabpanel">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr style={{ border: 'solid 1px #f1f1f2', backgroundColor: '#fafbfc', height: '60px', alignItems: 'center', textAlign: 'left', paddingLeft: '10px' }}>
                    <th scope="col" width='10%'className="not-to-print">Screenshot</th>
                                <th scope="col" width='20%'>Name & Date</th>
                                <th scope="col" width='25%'>Direct URL</th>
                                <th scope="col" width='15%'>Alexa Rank (global)</th>
                                <th scope="col" width='15%'> Alexa Rank Change</th>
                                <th scope="col" width='15%'> MOZ DA</th>
                                <th scope="col" width='15%'>Potential Visibility</th>
                                <th scope="col" width='15%'>Monthly Traffic</th>
                    
                    {/* <th scope="col" width='15%'>Monthly Traffic</th> */}

                  </tr>
                </thead>
                <tbody>
                  {reportData && reportData.length > 0 ? reportData.map(x => (
                    <tr className="inner-box">

                      <td className="not-to-print">
                        <div className="event-img">
                          {x.logo_url ?
                            <img src={x.logo_url} alt="" />
                            : 
                            // <img src={'https://'+x.url+'/favicon.ico'} alt=""/>
                           null}
                        </div>
                      </td>
                      <td>
                        <div className="event-wrap">
                          <div style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: 1.38, color: '#2d4b9d'}}>
                            {extractCompanyName(x.url)}
                          </div>
                          <span style={{ fontSize: '12px', lineHeight: 1.8, color: '#a0acb0' }}>
                            Captured  {x.date}
                             {/* {moment(x.captured).format('LT') + ' ' + moment(x.captured).format('l')} */}
                          </span>

                        </div>
                      </td>
                      <td>
                        <a target="_blank" href={x.url} style={{ fontWeight: 'bold', fontSize: '15px', lineHeight: 1.38, color: '#1f2f59', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {x.url}
                        </a><br />
                        <div style={{ width: '250px' }}>
                          <a target="_blank" href={x.link} className="directURL">
                            {x.link}
                          </a>
                        </div>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485985', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.alexa_rank_global}
                          
                              {/* it is alexarank global  */}
                        </span> <br />
      


                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485985', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.alexa_rank_change}
                          {/* it is alexarank change  */}
                        </span> <br />


                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485985', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.mozda}
                          {/* it is mozda */}
                        </span>
                      </td>
                      <td>

                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485984', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.potential_visibility} 
                          {/* it is potentail visibility */}
                        </span>
                       
                      </td>
                      <td>
                      <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485984', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.monthly_traffic}
                          {/* it is monthly trafic */}
                        </span>
                      </td>
                      
                    </tr>
                  )) : <tr>
                    <th scope="col" className="text-center" colSpan={7} width='100%'>Data empty</th>

                  </tr>}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};
export default PerformanceReportListView;
