import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Alert, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/logo.png";
 
const NavBarHeader = (data) => {
  const auth = data.data
  const navigate = useNavigate();
 
  const onClickLogout = (e) => {
    e.preventDefault();
    localStorage.setItem('useAuth', null);
    navigate('/');
  };
  return (
    <>
 <nav className="navbar navbar-expand-lg navbar-dark bg-light static-top">
        <div className="container">
          <Link className="navbar-brand" to="/client">
            <img
              className="mx-auto d-block mb-2"
              src={Logo}
              alt="logo"
            />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">

              <li className="nav-item dropdown">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" style={{ border: 'none' }}>
                    {auth.clientname}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Profile</Dropdown.Item>
                    <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li></ul>
          </div>
        </div>
      </nav>


    </>
  );
};
export default NavBarHeader;