import React, { useState,useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./order.css";

import BackgroundImage from "../../assets/images/background.jpg";
import Logo from "../../assets/images/logo.png";
// import NavBar from "../../../components/NavBar/index";

const Order = () => {
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [useAuth, setUseAuth] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('useAuth'));
    if (items && items.usertype == 'admin') {
      setUseAuth(items);
    }else{
      navigate('/');
    }
  

    setIsLoading(true)

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
   
let url ='https://perf-report-api.newsdirect.com/clientList';
// let url ='http://localhost:8000/clientList';
    fetch(url, requestOptions)
     .then(response =>  response.json()
    )
      .then(data =>{
        setClientData(data)
            setIsLoading(false)
      })
        .catch(error => {
           setIsLoading(false)
        });

  }, []);

  // const clientData = ['Benzinga','Frontieras','Trust Relations','Trevino Enterprises','Global Markets News','Little Spoon'];

  const sortedCompanies = clientData.sort((a, b) => {
    if (a.company_name < b.company_name) {
      return -1;
    }
    if (a.company_name > b.company_name) {
      return 1;
    }
    return 0;
  });

  const btnClick = (e) => {

    navigate('/articles',{ state: e });
  };
  const onClickLogout = (e) => {
    e.preventDefault();
    localStorage.setItem('useAuth', null);
    navigate('/');
  };


  return (
   <div> 

<nav className="navbar navbar-expand-lg navbar-dark bg-light static-top">
  <div className="container">
    <Link className="navbar-brand" to="/">
    <img
          className="mx-auto d-block mb-2"
          src={Logo}
          alt="logo"
        />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto">
       
       <li className="nav-item dropdown">
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic" style={{border:'none'}}>
        {useAuth.clientname}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#">Profile</Dropdown.Item>
        <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </li></ul>
    </div>
  </div>
</nav>

<div className="container">
    <h4 className="pb-2 mb-0 mt-3" style={{lineHeight:'36px'}}>Select account</h4>
<div className="my-3 bg-body rounded shadow-sm">
    <div className="mainNewsBox">
    {sortedCompanies.map(x => (
    <div className="d-flex text-muted newsBox border-bottom"   onClick={()=>btnClick(x.company_name)}>
      <div className="mb-0 small lh-sm  w-100">
        <div className="d-flex justify-content-between">
           <strong className="text-gray-dark">{x.company_name}</strong>
      
        </div>
      </div>

      
    </div>
    ))}
   </div>
  </div>
</div>

</div>
  );
};

export default Order;
