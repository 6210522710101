// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 10rem;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}
bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}`, "",{"version":3,"sources":["webpack://./src/views/login/login.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kCAAkC;EAClC,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;;IAEE,8BAA8B;EAChC;EACA;;IAEE,6BAA6B;EAC/B;EACA;;IAEE,0BAA0B;EAC5B;AACF;AACA;EACE,kBAAkB;EAClB,4EAA4E;AAC9E","sourcesContent":["/* ---------- SIGN IN ---------- */\r\n.sign-in__wrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 100vw;\r\n  height: 100vh;\r\n  background-position: center center;\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  position: relative;\r\n}\r\n\r\n.sign-in__backdrop {\r\n  position: absolute;\r\n  inset: 0;\r\n  background-color: rgba(0, 0, 0, 0.3);\r\n}\r\n\r\n.sign-in__wrapper form {\r\n  width: 24rem;\r\n  max-width: 90%;\r\n  z-index: 1;\r\n  animation: showSignInForm 1s;\r\n}\r\n\r\n.sign-in__wrapper form img {\r\n  width: 10rem;\r\n}\r\n\r\n@keyframes showSignInForm {\r\n  0%,\r\n  30% {\r\n    transform: translate(0, -150%);\r\n  }\r\n  70%,\r\n  90% {\r\n    transform: translate(0, 1rem);\r\n  }\r\n  80%,\r\n  100% {\r\n    transform: translate(0, 0);\r\n  }\r\n}\r\nbg-white {\r\n  --bs-bg-opacity: 1;\r\n  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
