import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import BackgroundImage from "../../assets/images/background.jpg";
import Logo from "../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("useAuth"));
    if (items) {
      if (items?.usertype == "admin") {
        navigate("/client");
      } else {
        navigate("/articles");
      }
    }
  });

  const handleSubmit = (e) => {
    setLoading(true);
    setShow(false);
    setErrorMessage("");
    console.log("handle submit");

    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    };

    //

    fetch("https://perf-report-api.newsdirect.com/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);

        if (data.message) {
          localStorage.setItem("useAuth", JSON.stringify(data.data));
          setLoading(false);
          if (data?.data?.usertype == "admin") {
            navigate("/client");
          } else {
            navigate("/articles");
          }
        } else {
          console.log("Error While Login", data.error);

          setErrorMessage(data.error);
          setShow(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error Catched", error);

        setShow(true);
        setLoading(false);
      });
  };

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* <Form className="shadow p-4 bg-white rounded"> */}
        {/* Header */}
        <img className="mx-auto d-block mb-2" src={Logo} alt="logo" />
        <div className="h4 mb-2 text-center">Sign In</div>
        {/* ALert */}
        {show ? (
          <Alert
            className="mb-2"
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
          >
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        {/* <Form.Group className="mb-2" controlId="checkbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group> */}
        {!loading ? (
          <Button className="w-100" variant="primary" type="submit">
            Log In
          </Button>
        ) : (
          <Button className="w-100" variant="primary" type="submit" disabled>
            Logging In...
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Login;
