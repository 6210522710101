import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./report.css";

const PerformanceReportListPrint = (data) => {
  const reportData = data.reportData
  return (
    <div className="row">
      <div className="col-lg-12">

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade active show" id="home" role="tabpanel">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr style={{ border: 'solid 1px #f1f1f2', backgroundColor: '#fafbfc', height: '60px', alignItems: 'center', textAlign: 'left', paddingLeft: '10px' }}>
                    <th scope="col" width='10%'>Screenshot</th>
                                <th scope="col" width='20%'>Date</th>
                                <th scope="col" width='25%'>Date & Direct URL</th>
                                <th scope="col" width='15%'>Alexa Traffic</th>
                                <th scope="col" width='10%'> MOZ DA</th>
                    {/* <th scope="col" width='15%'>Potential Visibility Monthly Traffic</th> */}
                    <th scope="col" width='15%'>Monthly Traffic</th>

                  </tr>
                </thead>
                <tbody>
                  {reportData && reportData.length > 0 ? reportData.map(x => (
                    <tr className="inner-box">

                      <td>
                        <div className="event-img">
                          {x.screenshot ?
                            <img src={x.screenshot} alt="" />
                            : null}
                        </div>
                      </td>
                      <td>
                        <div className="event-wrap">
                          <div style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: 1.38, color: '#2d4b9d',textTransform:'capitalize'}}>
                            {/* <div style={{width: '200px'}}> */}
                            {/* <a target="_blank" href={x.posting_site} style={{ display: '-webkit-box', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}>{x.posting_site}</a> */}
                            {/* </div> */}
                            {x.url.replace(".com", "")}
                          </div>
                          <span style={{ fontSize: '12px', lineHeight: 1.8, color: '#a0acb0' }}>
                            Captured   {moment(x.captured).format('LT') + ' ' + moment(x.captured).format('l')}
                          </span>

                        </div>
                      </td>
                      <td>
                        <a target="_blank" href={'http://'+x.url} style={{ fontWeight: 'bold', fontSize: '15px', lineHeight: 1.38, color: '#1f2f59', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {x.url}
                        </a><br />
                        <div style={{ width: '200px' }}>
                          <a target="_blank" href={x.direct_url} className="directURL">
                            {x.direct_url}
                          </a>
                        </div>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485985', textDecoration: 'underline', textDecorationStyle: 'dashed', marginLeft: '35px' }}>
                          {x.alexa_rank_global}
                        </span> <br />
                        {x.alexa_rank_change ?
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                            </svg>
                            <span style={{ fontSize: '14px', lineHeight: 1.8, color: '#a0acb0', marginLeft: '20px', marginRight: '50px' }}>
                              {x.alexa_rank_change}
                            </span>
                          </span> : null}


                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485985', textDecoration: 'underline', textDecorationStyle: 'dashed', marginLeft: '35px' }}>
                          {x.moz_da}
                        </span>
                      </td>
                      <td>

                        <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: 1.38, color: '#485984', textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
                          {x.potential_visibility}
                        </span>
                        <br />
                        <span style={{ fontSize: '14px', lineHeight: 1.8, color: '#485984' }}>
                          {x.monthly_traffic}
                        </span>
                      </td>
                    </tr>
                  )) : <tr>
                    <th scope="col" className="text-center" colSpan={7} width='100%'>Data empty</th>

                  </tr>}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};
export default PerformanceReportListPrint;
