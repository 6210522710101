// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr td .event-img img {
  width: 100px;
  height: auto;
  border-radius: 8px;
}
.bg-color{
  background-color: white;
  border-radius: 5px;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-left: 49%;
  margin-top: 17%;
}
.loading div {
  position: absolute;
  background: #641313;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.directURL{
  font-size: 12px;
    line-height: 1.8;
    color: rgb(160, 172, 176);
    text-overflow: ellipsis;
    text-decoration: underline;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
}

@media print {
  .not-to-print{
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PerformanceReport/report.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,6DAA6D;AAC/D;AACA;EACE,qBAAqB;AACvB;AACA;EACE;IACE,SAAS;IACT,UAAU;IACV,QAAQ;IACR,SAAS;IACT,UAAU;EACZ;EACA;IACE,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;AACF;AACA;EACE,eAAe;IACb,gBAAgB;IAChB,yBAAyB;IACzB,uBAAuB;IACvB,0BAA0B;IAC1B,gBAAgB;IAChB,oBAAoB;IACpB,4BAA4B;IAC5B,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["tr td .event-img img {\r\n  width: 100px;\r\n  height: auto;\r\n  border-radius: 8px;\r\n}\r\n.bg-color{\r\n  background-color: white;\r\n  border-radius: 5px;\r\n}\r\n.loading {\r\n  display: inline-block;\r\n  position: relative;\r\n  width: 64px;\r\n  height: 64px;\r\n  margin-left: 49%;\r\n  margin-top: 17%;\r\n}\r\n.loading div {\r\n  position: absolute;\r\n  background: #641313;\r\n  opacity: 1;\r\n  border-radius: 50%;\r\n  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;\r\n}\r\n.loading div:nth-child(2) {\r\n  animation-delay: -.7s;\r\n}\r\n@keyframes loading {\r\n  0% {\r\n    top: 28px;\r\n    left: 28px;\r\n    width: 0;\r\n    height: 0;\r\n    opacity: 1;\r\n  }\r\n  100% {\r\n    top: -1px;\r\n    left: -1px;\r\n    width: 58px;\r\n    height: 58px;\r\n    opacity: 0;\r\n  }\r\n}\r\n.directURL{\r\n  font-size: 12px;\r\n    line-height: 1.8;\r\n    color: rgb(160, 172, 176);\r\n    text-overflow: ellipsis;\r\n    text-decoration: underline;\r\n    overflow: hidden;\r\n    display: -webkit-box;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-line-clamp: 1;\r\n    white-space: pre-wrap;\r\n}\r\n\r\n@media print {\r\n  .not-to-print{\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
